import React from 'react';
import {
  ServicesContainer,
  Heading,
  Heading1,
  Subtitle,
  Subtitle1,
  Welcome,
  DirectionFlex,
  Welcomeimg,
  Subtitle1Span,
  SubtitleSpan,
  // ServicesWrapper,
  //Cardstyle,
  // ServicesCard,
  // ServicesIcon,
  // ServicesH2,
  // ServicesP,
} from './ServicesElements';
import Faq1 from '../../images/Welcome.png';

// import Faq2 from "../../images/faq2.png";
// import Card from "./filp";
const Services = props => {
  return (
    <ServicesContainer id='about'>
      <Welcome>
        <DirectionFlex>
          <Heading>
            WELCOME TO THE&nbsp;<Heading1>CHEEKY LION CLUB</Heading1>
          </Heading>
          <div style={{ flexDirection: 'column' }}>
            <Subtitle>
              THE CHEEKY LION CLUB IS A COLLECTION OF 8,000 CHEEKY LION NFTS{' '}
              <br />
              <div className='margin-letter-space'></div>
              <SubtitleSpan>
                CLAWING THEIR WAY ONTO THE ETHEREUM BLOCKCHAIN.
              </SubtitleSpan>
            </Subtitle>
            <Subtitle1>
              YOUR LION WILL GRANT YOU ACCESS TO MEMBER ONLY BENEFITS, <br />
              <div className='margin-letter-space'></div>
              <Subtitle1Span> INCLUDING THE CLUB BAR.</Subtitle1Span>
            </Subtitle1>
          </div>
        </DirectionFlex>

        <Welcomeimg src={Faq1} alt='loading' />
      </Welcome>
    </ServicesContainer>
  );
};

export default Services;
