import React from 'react';
import {
  ServicesContainer,
  Heading,
  Subtitle,
  Heading1,
  Welcome,
  Welcomeimg,
  ImgAdjustment,
  SubtitleSpan,
  // ServicesWrapper,
  //Cardstyle,
  // ServicesCard,
  // ServicesIcon,
  // ServicesH2,
  // ServicesP,
} from './ServicesElements';
import Faq1 from '../../images/roadmapgiveaway.png';

// import Faq2 from "../../images/faq2.png";
// import Card from "./filp";
const Services = props => {
  return (
    <ServicesContainer id='story'>
      <Welcome>
        <div style={{ flexDirection: 'row' }}>
          <Heading>Roadmap</Heading>
          <Heading1>1. GiveAways</Heading1>
          <div style={{ flexDirection: 'column' }}>
            <Subtitle>
              Strap on, there are going to be multiple giveaways giving you a
              <br />
              <div className='margin-letter-space'></div>
              <SubtitleSpan>
                chance to win one of our cheeky lion NFTs, allowing you to be a
                <br />
                <div className='margin-letter-space'></div>
                <SubtitleSpan>part of our exclusive community.</SubtitleSpan>
              </SubtitleSpan>
            </Subtitle>
          </div>
        </div>
        <ImgAdjustment>
          <Welcomeimg src={Faq1} alt='loading' style={{ width: '80%' }} />
        </ImgAdjustment>
      </Welcome>
    </ServicesContainer>
  );
};

export default Services;
