import React from "react";
import {
  ServicesContainer,
  ServicesWrapper,
  // ServicesWrapper,
  //Cardstyle,
  // ServicesCard,
  // ServicesIcon,
  // ServicesH2,
  // ServicesP,
} from "./ServicesElements";
import Faq1 from "../../images/welcomecheeky.png";
import Faq2 from "../../images/faq2.png";
// import Discord from "../../images/Opensea.png";
import Insta from "../../images/Instagram.png";
import Twitter from "../../images/Twitter.png";
import Opensea from "../../images/Discode.png";
import CLC from "../../images/footerClC.png";
// import Card from   "./filp";
const Services = (props) => {
  return (
    <ServicesContainer id="faq">
      <ServicesWrapper>
        <img src={Faq2} alt="loading" style={{ width: "40%" }} />
        <div
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img style={{ width: "80%" }} src={CLC} alt="loading" />

          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              display: "flex",
              width: "120%",
            }}
          >
            {/* <img
              src={Discord}
              alt="loading"
              style={{ width: 30, height: 30 }}
            /> */}
            <a
              href="https://www.instagram.com/cheekylionclub/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Insta}
                alt="loading"
                style={{ width: 30, height: 30 }}
              />
            </a>
            <a
              href="https://discord.com/invite/cheekylionclub"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Opensea}
                alt="loading"
                style={{ width: 30, height: 30 }}
              />
            </a>
            <a
              href="https://twitter.com/CheekyLionClub"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Twitter}
                alt="loading"
                style={{ width: 30, height: 30 }}
              />
            </a>
          </div>
          <div style={{ marginTop: 20 }}>
            <p style={{ color: "#605f5b", fontSize: 8 }}>
              {" "}
              The Cheeky Lion Club
            </p>
          </div>
        </div>
        <img src={Faq1} alt="loading" style={{ width: "40%" }} />
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
